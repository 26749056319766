import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../components/Button/Button";
import Layout from "../components/layout";
import MainMenu from "../components/MainMenu/MainMenu";
import Footer from "../components/Footer/Footer";
import CopyRights from "../components/Footer/CopyRights/CopyRights";
import SEO from "../components/seo";
import Typography from "../components/Typography/Typography";
import { mobileViewBreakpoint } from "../components/helper";
const useStyles = makeStyles(theme => ({
  notFoundWrapper: {
    paddingTop: "15rem",
    paddingBottom: "10rem",
    textAlign: "center",
    width: "90%",
    margin: "0 auto",
    "& > h5": {
      fontSize: "20px",
      color: " #CC4A01",
      fontWeight: "bold",
      marginBottom: "8px",
      [theme.breakpoints.up(mobileViewBreakpoint)]: {
        marginBottom: "16px"
      }
    },
    "& > h1": {
      fontSize: "30px",
      fontWeight: "bold",
      marginBottom: "24px",
      color: "#122044;",
      marginTop: "0px",
      [theme.breakpoints.up(mobileViewBreakpoint)]: {
        fontSize: "60px"
      }
    },
    "& > p": {
      fontSize: "20px",
      color: " rgba(0, 0, 0, 0.6)",
      marginBottom: "32px"
    }
  }
}));

const NotFoundPage = () => {
  const classes = useStyles();
  return (
    <Layout>
      <MainMenu />
      <SEO
        seo={{
          metaTitle: "404: Not found",
          metaDescription: "It looks like you got lost"
        }}
      />
      <div className={classes.notFoundWrapper}>
        <Typography customVariant={"h5Bold"}>404</Typography>
        <Typography customVariant={"h1Bold"}>
          Oops we ran into a problem
        </Typography>
        <Typography customVariant={"bodyLargeRegular"}>
          Please try again or contact us
        </Typography>
        <Button type="submit" variant="contained" color="primary">
          Contact Us
        </Button>
      </div>
      <Footer />
      <CopyRights />
    </Layout>
  );
};

export default NotFoundPage;
